<template>
<v-container class="container-max-width fill-height">
  <v-row class="text-center" align="center" justify="center">
    <v-col cols="12">
      <setting-up-syncio></setting-up-syncio>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {LOGIN_SHOPIFY} from "@/store/actions.type";
import SettingUpSyncio from "@/views/registration/components/SettingUpSyncio";
export default {
  name: "ShopifyEntry",
  components: {SettingUpSyncio},
  created() {
    let storeName = this.$route.query.shop;
    console.log(storeName);
    this.$store.dispatch(`auth/${LOGIN_SHOPIFY}`, {storeName})
        .then((data) => {
        if (data.success === true && typeof data.redirect_url != "undefined"){
          let redirectUrl = data.redirect_url;
          redirectUrl += process.env.VUE_APP_SHOPIFY_RESPONSE_REDIRECT_URI;
          window.location.href = redirectUrl;
        }else{
          this.$router.push({name: 'dashboard'})
        }
    });
  },
}
</script>

<style scoped>
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
</style>