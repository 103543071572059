<template>
<v-container>
  <v-row>
    <v-col>
      <v-row class="d-inline-block text-center">
        <v-col>
          <v-img class="rotate" src="~@/assets/images/logo.svg" height="98px" width="75px" contain></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col>
          <div class="text-h2 primary--text">Installing Syncio</div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "SettingUpSyncio"
}
</script>

<style scoped>
.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>